import React from "react"

import Layout from "../components/layout"
import HomePage from "../components/marketing/homepage"
import SEO from "../components/seo"

const HomePageComp = () => (
  <Layout>
    <SEO title="Home Disposal Services" />
    <HomePage/>
  </Layout>
)

export default HomePageComp
